/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('AccManagementCtrl',
      ['$scope', '$http', '$location', 'FormMessages', 'SettingsService', 'userServ', 'apiPrefixUrl',
        function($scope, $http, $location, FormMessages, SettingsService, userServ, apiPrefixUrl) {
            $scope.errors = {
                all: [],
                email: [],
                password: [],
                confPass: []
            };
            $scope.usettings = {};
            var usettingsOld = {};
            $scope.userid = userServ.read('id');
            $scope.email_verified = $location.search().email_verified;
            SettingsService.getUserSettings($scope.userid).then(function(resp) {
                var data = resp.data;
                $scope.usettings.email = resp.data.email;
                usettingsOld.email = resp.data.email;
                $scope.usettings.password = '';
                usettingsOld.password = '';
                $scope.usettings.confPass = '';
                usettingsOld.confPass = '';
            }, function() {
                $scope.errors.all.push('There was an communication error, please try again later!');
            });
            $scope.clearConfirm = function() {
                if($scope.usettings.password === '') {
                    $scope.usettings.confPass = '';
                }
            };
            var createPatchObj = function() {
                var formArr = ['email', 'password'],
                    patchObj = {},
                    curr,
                    i,
                    el;

                for (i = 0; i < formArr.length; i++) {
                    el = formArr[i];
                    curr = $scope.usettings.userSettings[el];
                    if (curr.$dirty && curr.$modelValue !== usettingsOld[el]) {
                        patchObj[el] = curr.$modelValue;
                    }
                }
                return patchObj;
            };
            $scope.changeSettings = function() {
                $scope.usettings.sent = true;
                $scope.saved = false;
                $scope.errors = {
                    all: [],
                    email: [],
                    password: [],
                    confPass: []
                };
                if ($scope.usettings.userSettings.$invalid) {
                    $scope.errors = FormMessages.prepErrArr($scope.usettings.userSettings);
                    return false;
                }
                if ($scope.usettings.userSettings.$dirty) {
                    $http({
                            method: 'PATCH',
                            url: apiPrefixUrl + '/accounts/settings/' + $scope.userid,
                            data: angular.toJson(createPatchObj())
                        })
                        .then(function(resp) {
                            var data = resp.data;
                            $scope.saved = true;
                            if($scope.usettings.email !== data.email) {
                                $scope.emailChanged = true;
                            }
                            $scope.usettings.email = data.email;
                            $scope.usettings.password = '';
                            $scope.usettings.confPass = '';
                            usettingsOld = $scope.usettings;
                            $scope.email_verified = false;
                        }, function(resp) {
                            var data = resp.data;
                            if (typeof data === typeof {} || typeof data === typeof []) {
                                if (data.msg) {
                                    $scope.errors['all'].push(data.msg);
                                } else {
                                    $scope.errors = data;
                                }
                            } else {
                                $scope.errors['all'].push('Server communication error');
                            }
                        });
                }
            }
        }
    ]);

