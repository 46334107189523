/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('LoginCtrl', ['$scope', '$location', '$http', 'userServ', '$rootScope', '$routeParams', 'apiPrefixUrl', 'WaterJwt',
        function($scope, $location, $http, userServ, $rootScope, $routeParams, apiPrefixUrl, WaterJwt){
        var confSuccess;
        $scope.errors = [];
        $scope.confirmation_key = $location.search().confirmation_key;
        $scope.confState = $location.search().confirmed;
        if($scope.confState){
            confSuccess = $scope.confState === 'true';
            $scope.verified = true;
            $scope.logHeader = confSuccess ? 'Success!' : 'Error!';
            $scope.logSubHeader = confSuccess ? 'Your email have been successfully verified!' : 'Your email has not been verified, please try register again!';
        }
        $scope.shopifyLogin = ($location.search().rap === 'shopify');
        if($scope.shopifyLogin) {
            if(localStorage.shopifyUser){
                $scope.username = JSON.parse(localStorage.shopifyUser).email;
            }
        }
        $scope.login = function(){
          var postData = {
                  username: $scope.username,
                  password: $scope.password
              };

            $scope.errors = [];

            if($scope.loginForm.$invalid){
                $scope.errors.push('Please fill required fields');
                return false;
            }

            if($scope.confirmation_key){
                postData.confirmation_key = $scope.confirmation_key;
            }

            $http.post(apiPrefixUrl + '/login', postData)
                .then(function(resp){
                    var data = resp.data;
                    // registering user data
                    userServ.register(data);
                    WaterJwt.get();

                    $rootScope.$broadcast('login-success');
                    if($scope.shopifyLogin) {
                        $location.path('/shopify/landingpage');
                    } else {
                        if(data.email_verified){
                          $location.path('/campaigns').search('email_verified=' + data.email_verified);
                        }
                        else{
                          // redirecting to main search page
                          $location.path('/campaigns').search('token', null);
                        }
                    }
                }, function(resp){
                    var data = resp.data;
                    if(data.msg){
                        $scope.errors.push(data.msg);
                    }
                    else {
                        $scope.errors.push('Server communication error');
                    }
                });
        }

        $scope.loginAs = function(userId){
            var token;
            $scope.errors = [];

            $http.post(apiPrefixUrl + '/appnexus/advertisers/' + userId + '/generate_token')
                .then(function(resp){
                    var data = resp.data;
                    token = data.token;

                    userServ.registerTemporary({
                        token: token
                    });

                    $http.get(apiPrefixUrl + '/accounts/me')
                        .then(function(resp){
                            var data = resp.data;
                            WaterJwt.setUserAirId(data.id);
                            data.token = token;
                            userServ.registerTemporary(data);
                            userServ.setAsAdminFromAdminPanel();
                            $rootScope.$broadcast('login-success');
                            $location.path('/campaigns');
                        }, function(){
                            $scope.errors.push('Error. Received token for second login but could not get user details data.');
                        });
                }, function(){
                    $scope.errors.push('Error. Server could not generate token for second login session')
                });
        };

        $scope.loginExternal = function(token) {
            $scope.errors = [];
            userServ.register({
                token: token
            });
            $http.get(apiPrefixUrl + '/accounts/me')
                .then(function(resp){
                    var data = resp.data;
                    // registering user data
                    data.token = token;
                    userServ.register(data);

                    $rootScope.$broadcast('login-success');
                    if(data.email_verified){
                      $location.path('/').search('email_verified=' + data.email_verified);
                    }
                    else{
                      // redirecting to main search page
                      $location.path('/').search('token', null);
                    }
                }, function(resp){
                    var data = resp.data;
                    if(data.msg){
                        $scope.errors.push(data.msg);
                    }
                    else {
                        $scope.errors.push('Server communication error');
                    }
                    userServ.unregister();
                });
        };


        if($routeParams.userid && userServ.isRegistered()){
            // handle secondary login
            $scope.loginAs($routeParams.userid);
        }

        else if($location.search().token) {
            $scope.loginExternal($location.search().token);
        }

        else if (userServ.isRegistered()){
            $location.path('/campaigns');
        }
    }]);

