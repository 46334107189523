/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
  .controller('ResetPassCtrl', ['$scope', '$http', '$location', 'FormMessages', 'apiPrefixUrl',
    function($scope, $http, $location, FormMessages, apiPrefixUrl) {
      $scope.errors = {
        all: [],
        segment: [],
        email: [],
        password: [],
        confPass: []
      };
      $scope.regForm = {};
      $scope.step = 0;
      $scope.token = $location.search().token;
      if($scope.token){
        $scope.step = 1;
      }

      $scope.reset_token = function() {
        $scope.errors = {
          all: [],
          email: [],
        };
        if ($scope.regForm.registerForm.$invalid) {
          $scope.errors = FormMessages.prepErrArr($scope.regForm.registerForm);
          return false;
        }

        $http.post(apiPrefixUrl + '/accounts/recover_password', {
            email: $scope.regForm.email,
          })
          .then(function(resp) {
            $scope.step = 2;
            $scope.sublabel = "Email with instructions sent!"
          }, function(resp) {
            var data = resp.data;
            if (typeof data === typeof {} || typeof data === typeof []) {
              if (data.msg) {
                $scope.errors['all'].push(data.msg);
              } else {
                $scope.errors = data;
              }
            } else {
              $scope.errors['all'].push('Server communication error');
            }
          });
      }

      $scope.reset_password = function() {
        $scope.errors = {
          all: [],
          segment: [],
          email: [],
          password: [],
          confPass: []
        };
        if ($scope.regForm.registerForm.$invalid) {
          $scope.errors = FormMessages.prepErrArr($scope.regForm.registerForm);
          return false;
        }

        $http.post(apiPrefixUrl + '/accounts/reset_password', {
            token: $scope.token,
            password: $scope.regForm.password,
          })
          .then(function(resp) {
            $scope.step = 2;
            $scope.sublabel = "Pasword has been changed succesfully, you can now login."
          }, function(resp) {
            var data = resp.data;
            if (typeof data === typeof {} || typeof data === typeof []) {
              if (data.msg) {
                $scope.errors['all'].push(data.msg);
              } else {
                $scope.errors = data;
              }
            } else {
              $scope.errors['all'].push('Server communication error');
            }
          });
      }

    }
  ]);

