/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('DepositCtrl',
      ['$scope', 'userServHelper', 'Card', '$http', 'PAYMENT_AMOUNTS', 'apiPrefixUrl', 'userServ',
        function ($scope, userServHelper, Card, $http, PAYMENT_AMOUNTS, apiPrefixUrl, userServ) {

        $scope.cards = [];
        $scope.payment = {
            amounts: PAYMENT_AMOUNTS,
            recurring: {},
            onetime: {}
        };

        $scope.submitsDisabled = false;
        $scope.isAdmin = userServ.isFromAdminPanel();

        var cardsImages = [
            {name: 'American Express', iconName: 'american_express.png'},
            {name: 'Amex', iconName: 'american_express.png'},
            {name: 'Cirrus', iconName: 'cirrus.png'},
            {name: 'Diners Club', iconName: 'diners_club.png'},
            {name: 'Discover', iconName: 'discover.png'},
            {name: 'Maestro', iconName: 'maestro.png'},
            {name: 'MasterCard', iconName: 'mastercard.png'},
            {name: 'Visa', iconName: 'visa.png'},
            {name: 'Visa Electron', iconName: 'visa_electron.png'},
        ];

        // load user balance
        userServHelper.getBalance(function(balance){
            $scope.balance = balance;
        });

        $scope.loadCards = function(){
            $scope.cards = [];
            $scope.cardsMsg = ['Loading forms of payment...', 'alert-info'];

            Card.query({}, function(res){
                $scope.cardsMsg = [];
                $scope.cards = res;
            }, function(){
                $scope.cardsMsg = ['Cards data could not be loaded', 'alert-danger'];
            });
        };

        $scope.getCardIconName = function(name) {
            for(var i = 0; i < cardsImages.length; i++) {
                if(cardsImages[i].name === name) {
                    return cardsImages[i].iconName;
                }
            }
            return 'no_image.png';
        };

        $scope.submitRecurring = function(){
            var card = $scope.payment.recurring.card;

            if(!card){
                $scope.payment.recurring.msg = ['Choose card first', 'alert-danger'];
                return false;
            }

            var reqConfig = {
                url: apiPrefixUrl + '/payment/recurring/' + card.payment_id
            };

            if($scope.payment.recurring.on){
                reqConfig.method = 'PUT';
                reqConfig.data = {
                    recurring: true,
                    recurring_active: true,
                    recurring_amount: $scope.payment.recurring.amount
                };
            }
            else {
                reqConfig.method = 'DELETE';
            }

            $scope.payment.recurring.msg = ['Saving...', 'alert-info'];
            $scope.submitsDisabled = true;

            $http(reqConfig).then(function(){
                $scope.submitsDisabled = false;
                $scope.payment.recurring.msg = ['Recurring payment updated', 'alert-success'];
                $scope.loadCards();
                $scope.loadLastRecurringPayment();
            }, function(resp){
                var data = resp.data;
                $scope.submitsDisabled = false;
                $scope.loadLastRecurringPayment();
                if(data.message){
                    $scope.payment.recurring.msg = [data.message.join(' '), 'alert-danger'];
                }
                else {
                    $scope.payment.recurring.msg = ['Server communication error', 'alert-danger'];
                }
            });
        };

        $scope.submitOneTime = function(){
            if(!$scope.payment.onetime.card){
                $scope.payment.onetime.msg = ['Choose card first', 'alert-danger'];
                return false;
            }

            if(!$scope.payment.onetime.amount){
                $scope.payment.onetime.msg = ['Choose cash amount', 'alert-danger'];
                return false;
            }

            $scope.payment.onetime.msg = ['Saving...', 'alert-info'];
            $scope.submitsDisabled = true;

            $http.post(apiPrefixUrl + '/payment/onetime', {
                payment_id: $scope.payment.onetime.card.payment_id,
                amount: $scope.payment.onetime.amount
            }).then(function(){
                $scope.submitsDisabled = false;
                $scope.payment.onetime.msg = ['One time payment charged', 'alert-success'];
                $scope.loadCards();
                $scope.loadLastOneTimePayment();
            }, function(resp){
                var data = resp.data;
                $scope.submitsDisabled = false;
                $scope.payment.onetime.msg = ['One time payment charged', 'alert-success'];
                $scope.loadLastOneTimePayment();
                if(data.message){
                    $scope.payment.onetime.msg = [data.message.join(' '), 'alert-danger'];
                }
                else {
                    $scope.payment.onetime.msg = ['Server communication error', 'alert-danger'];
                }
            });
        }

        $scope.determineRecurringCard = function(){
            $scope.payment.recurring.card = null;
            $scope.payment.recurring.current = null;

            $scope.cards.forEach(function(item){
                if (item.recurring && item.recurring.recurring_active){
                    $scope.payment.recurring.card = item;
                    $scope.payment.recurring.current = item;
                }
            });
        };

        $scope.loadLastRecurringPayment = function(){
            $http.get(apiPrefixUrl + '/payment/recurring/status')
                .then(function(resp){
                    $scope.payment.recurring.status = resp.data;
                });
        };

        $scope.loadLastOneTimePayment = function(){
            $http.get(apiPrefixUrl + '/payment/onetime/status')
                .then(function(resp){
                    $scope.payment.onetime.status = resp.data;
                });
        };

        $scope.isPaymentStatusFailed = function(statusData){
            if(statusData){
                return !_.contains(['OK', 'processing'], statusData.charge_status);
            }
            return false;
        };

        $scope.loadLastRecurringPayment();
        $scope.loadLastOneTimePayment();
        $scope.loadCards();

        $scope.$watch('cards', function(){
            $scope.determineRecurringCard();
        });

        $scope.$watch('payment.recurring.card', function(){
            var card = $scope.payment.recurring.card;
            if(card && card.recurring.recurring && card.recurring.recurring_active){
                $scope.payment.recurring.amount = card.recurring.recurring_amount;
                $scope.payment.recurring.on = true;
            }
            else {
                $scope.payment.recurring.amount = 0;
                $scope.payment.recurring.on = false;
            }
        });

        $scope.$watch('payment.recurring.amount', function(newv, oldv){
            if(newv !== oldv && newv !== 0){
                $scope.payment.recurring.on = true;
            }
        });

        $scope.$on('msg:deposit', function(e, msg, type){
            $scope.msg = [msg, type];
        });

        $scope.$on('popover-opened', function(){
            $scope.msg = [];
        });

        $scope.$on('deposit:cards-reload', $scope.loadCards);
    }]);

