/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('ConversionsListCtrl',
      ['$scope', 'EventsList', 'time', '$filter', 'newApiPrefixUrl', 'utils', 'Tables', 'WaterJwt',
        function($scope, EventsList, time, $filter, newApiPrefixUrl, utils, Tables) {
            var dateFilter = $filter('date'),
                EXCEL_FILE_URL = newApiPrefixUrl + '/events/conversions/excel',
                table = Tables.create($scope, EventsList.getConversionList, function (params, scope) {
                    params.hide_visitors = scope.hideVisitors ? 1 : 0;
                });

            table.createColumn("line_item_name", "Line Item");
            table.createColumn("pixel_name", "Pixel Name");
            table.createColumn("post_click_or_post_view_conv", "Conversion Type");
            table.createColumn("post_click_or_post_view_revenue", "Revenue");
            table.createColumn("order_id", "Order Id");
            table.createColumn("days_to_convert", "Days To Convert");
            table.createColumn("imp_time", "Impression", function(row) {return dateFilter(row[this.key], "medium")});
            table.createColumn("datetime", "Conversion", function(row) {return dateFilter(row[this.key], "medium")});

            $scope.getExportToExcelURL = function(){
                return utils.getUrlWithSearchParams(
                    EXCEL_FILE_URL,
                    {
                        from_date: time.getBackendDate($scope.startDate),
                        to_date: time.getBackendDate($scope.endDate),
                        user_id: $scope.userId,
                        hide_visitors: $scope.hideVisitors ? 1 : 0
                    }
                );
            };

            $scope.hideVisitors = 0;
        }
    ]);

