/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('TagsCtrl', ['$scope', 'TagService', '$rootScope', '$location', '$http', 'piggybackUrls', 'apiPrefixUrl',
        function($scope, TagService, $rootScope, $location, $http, piggybackUrls, apiPrefixUrl) {
            $scope.showShopifySuccessMsg = false;
            if($location.search().rap === 'shopify') {
                $scope.showShopifySuccessMsg = true;
            }
            $scope.closeShopifySuccessMsg = function() {
                $location.search('');
                $scope.showShopifySuccessMsg = false;
            };
            $scope.websites = [];
            $scope.status = {isJSOpen:true};
            $scope.piggybackUrls = piggybackUrls;

            $scope.showTagsInformationMsg = false;
            $scope.anyPartnerEnabled = false;
            $scope.getTags = TagService.getTags().then(function(resp){
                $scope.websites = resp.data;
                for(var i=0; i<$scope.websites.length;i++) {
                    if(!$scope.websites[i].name_of_rap) {
                        $scope.showTagsInformationMsg = true;
                        break;
                    }
                }
            });
            $http.get(apiPrefixUrl + '/ra_partners').then(function(resp) {
                $scope.partners = resp.data;
                for(var i=0; i<$scope.partners.length;i++) {
                    if($scope.partners[i].enabled) {
                        $scope.anyPartnerEnabled = true;
                        break;
                    }
                }
            });
            $rootScope.$broadcast('tags-opened');
        }]);

