/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('SiteManagementCtrl', ['$scope', '$http', '$routeParams', '$location', 'TagService', 'userServ', 'SegmentList', 'apiPrefixUrl',
        function($scope, $http, $routeParams, $location, TagService, userServ, SegmentList, apiPrefixUrl) {
            var siteId = $routeParams.siteid, i,
                userId = userServ.read('id'),
                errrorServerMsg = 'There has been problem with server, please try again later';

            $scope.siteMissingSegment = false;

            $scope.site = {};

            $scope.errors = {
                name: null,
                segment: null,
                other: null
            };

            if(siteId === 'add') {
                $scope.site.name = '';
                $scope.site.segment = 0;
                $scope.mode = 'add';
                SegmentList.getCachedSegmentList().then(function(segments) {
                    $scope.segments = segments;
                    $scope.errors.segment = null;
                }, function() {
                    $scope.errors.segment = errrorServerMsg;
                });
            }
            else {
                $scope.mode = 'edit';
                var siteFound = false;
                TagService.getTags().then(function(resp){
                    var data = resp.data;
                    for(i = 0; i < data.length; i++) {
                        if('' + data[i].id === siteId) {
                            siteFound = true;
                            $scope.site.name = data[i].name;
                            $scope.site.segment = data[i].segment;
                            if(data[i].segment === null) {
                                $scope.siteMissingSegment = true;
                            }
                            break;
                        }
                    }
                    if(!siteFound) {
                        $location.path('/');
                    } else {
                        SegmentList.getCachedSegmentList().then(function(segments) {
                            var data = segments;
                            $scope.segments = data;
                            $scope.errors.segment = null;
                            for(i = 0; i < data.length; i++) {
                                if(data[i].id === $scope.site.segment) {
                                    $scope.site.segmentName = data[i].name;
                                }
                            }
                        }, function() {
                            $scope.errors.segment = errrorServerMsg;
                        });
                    }
                }, function() {
                    $scope.errors.other = errrorServerMsg;
                });
            }



            $scope.save = function() {
                var domainRegexp = new RegExp(/^((?!(http|www)).)*$/,'i');
                if($scope.site.name === '')  {
                    $scope.errors.name = 'Please enter your website URL';
                } else if (! domainRegexp.test($scope.site.name)) {
                    $scope.errors.name = 'Please enter your website URL without the www or http preface!';
                } else {
                    $scope.errors.name = null;
                }
                if($scope.site.segment === 0 || $scope.site.segment === null) {
                    $scope.errors.segment = 'Please select the category';
                } else {
                    $scope.errors.segment = null;
                }
                $scope.errors.other = null;
                if($scope.errors.name === null && $scope.errors.segment === null) {
                    if($scope.mode === 'add') {
                        var postData = {
                            advertiser_profile: userId,
                            name: $scope.site.name,
                            segment: $scope.site.segment
                        };
                        $http.post(apiPrefixUrl + '/tagmanager/site/manage', postData).then(
                          function (resp) {
                            //TODO: refresh sites list
                            $location.path('/').search('site-id', resp.data.id);
                        }, function(resp) {
                            var data = resp.data;
                            var status = resp.status;
                            if(status === 400) {
                                if(data.name) {$scope.errors.name = data.name[0];}
                                if(data.advertiser_profile) {$scope.errors.other = data.advertiser_profile[0];}
                            }
                            else {
                                $scope.errors.other = errrorServerMsg;
                            }
                        });
                    }
                    if($scope.mode === 'edit') {
                        var pathData = {
                            name: $scope.site.name
                        };
                        if($scope.siteMissingSegment) {
                            pathData.segment = $scope.site.segment;
                        }
                        $http.put(apiPrefixUrl + '/tagmanager/site/manage/' + siteId, pathData).then(
                          function(resp) {
                            //TODO: refresh sites list
                            $location.path('/').search('site-id', resp.data.id);
                        }, function(resp) {
                            var data = resp.data;
                            var status = resp.status;
                            if(status === 400) {
                                if(data.name) {$scope.errors.name = data.name[0];}
                                if(data.advertiser_profile) {$scope.errors.other = data.advertiser_profile[0];}
                            }
                            else {
                                $scope.errors.other = errrorServerMsg;
                            }
                        });
                    }
                }
            };
        }
    ]);

