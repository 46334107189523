/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('ShopifyCtrl', ['$scope', '$location', '$http', 'userServ', '$routeParams', 'SegmentList', 'TagService',
        function($scope, $location, $http, userServ, $routeParams, SegmentList, TagService){

            //*******Shopify strange url fix*******

            if(window.location.search) {
                var parseLocation = function(location) {
                    var pairs = location.substring(1).split("&");
                    var obj = {};
                    var pair;
                    var i;
                    for ( i in pairs ) {
                      if ( pairs[i] === "" ) continue;
                      pair = pairs[i].split("=");
                      obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
                    }
                    return obj;
                  };
                $location.search(parseLocation(window.location.search));
                window.location.search = '';
            } else {
            $scope.showAnyContent = true;

            //*******General integration error*******

            $scope.generalError = false;
            var generalErrorBehavior = function() {
	            	$scope.generalError = true;
	            },
	            queryString = $location.search(),
	        	sessionCheck = function() {
	        		$scope.loged = userServ.isRegistered();
	        		if(Object.keys(queryString).length === 0) {
	        			generalErrorBehavior();
	        		} else if(queryString.rap && !$scope.loged) {
	        			generalErrorBehavior();
	        		}
	        	},
	        	userId = userServ.read('id'),
	        	errrorServerMsg = ['There has been problem with server, please try again later'],
	        	self = this;
        	sessionCheck();
            $scope.$on('loginRequired', sessionCheck);

            //*******Initial request*******

            var shopifyUserData = {};
            if(!queryString.rap) {
              $http
                .post('/shopify/landingpage', {}, {params: queryString})
                .then(function(resp) {
                    var data = resp.data;
                    if(data.connected) {
                      $location.path('/tags').search('');
                    } else {
                      shopifyUserData = data;
                      localStorage.setItem('shopifyUser', JSON.stringify(data));
                    }
	            }, function() {
	            	generalErrorBehavior();
	            });
			} else {
        if(localStorage.shopifyUser){
            shopifyUserData = JSON.parse(localStorage.shopifyUser);
        } else {
            generalErrorBehavior();
        }
			}

        	//*******Create site*******

        	$scope.newSite = {};
            $scope.errors = {
                name: null,
                segment: null,
                other: null
            };
            $scope.newSite.name = '';
            $scope.newSite.segment = 0;
            SegmentList.getSegmentList().then(function(resp) {
                $scope.segments = resp.data;
                $scope.errors.segment = null;
            }, function() {
                $scope.errors.segment = errrorServerMsg;
            });

            $scope.createNewSite = function() {
                if($scope.newSite.name === '')  {
                    $scope.errors.name = ['Please enter your website URL'];
                } else if (!/^((?!(http|www)).)*$/.test($scope.newSite.name)) {
                    $scope.errors.name = ['Please enter your website URL without the www or http preface!'];
                } else {
                    $scope.errors.name = null;
                }
                if($scope.newSite.segment === 0 || $scope.newSite.segment === null) {
                    $scope.errors.segment = ['Please select the category'];
                } else {
                    $scope.errors.segment = null;
                }
                $scope.errors.other = null;
                if($scope.errors.name === null && $scope.errors.segment === null) {
                    var postData = {
                        advertiser_profile: userId,
                        domain: $scope.newSite.name,
                        segment: $scope.newSite.segment
                    };
                    $http.post('/shopify/sites/create?shop=' + shopifyUserData.shop,
                        postData
                    ).then(function() {
                        $location.path('/tags').search('').search('rap', 'shopify');
                    }, function(resp) {
                      var data = resp.data;
                      var status = resp.status;
                        if(status === 400) {
                            if(data.errors) {
                                if(data.errors.domain) {$scope.errors.name = data.errors.domain;}
                                if(data.errors.advertiser_profile) {$scope.errors.other = data.errors.advertiser_profile;}
                                if(data.errors.segment) {$scope.errors.segment = data.errors.segment;}
                                if(data.errors.shop) {$scope.errors.other = data.errors.shop;}
                            } else {
                                $scope.errors.other = errrorServerMsg;
                            }
                        } else if(status === 403) {
                        	$scope.errors.other = [data.msg];
                        }
                        else {
                            $scope.errors.other = errrorServerMsg;
                        }
                    });
                }
            };

            //*******Select Site*******

            $scope.errors.sites = null;
            $scope.errors.sitesList = false;

            if($scope.loged) {
                TagService.getTags($routeParams.user_id).then(function(resp){
                    $scope.sitesList = resp.data;
                    self.selectedSite = $scope.sitesList[0].id;
                })
                .catch(function() {
                    $scope.errors.sites = errrorServerMsg;
                    $scope.errors.sitesList = true;
                });
            }

            $scope.integrateSelectedSite = function() {
            	if(!$scope.errors.sitesList) {
					$http.put('/shopify/sites/connect/' + self.selectedSite + '?shop=' + shopifyUserData.shop
                    ).then(function() {
                        $location.path('/tags').search('').search('rap', 'shopify');
                    }, function(resp) {
                        var data = resp.data;
                        var status = resp.status;
                        if(status === 400) {
                            if(data.errors) {
                                if(data.errors.shop) {
                                    $scope.errors.sites = data.errors.shop;
                                }
                            } else {
                                $scope.errors.sites = errrorServerMsg;
                            }
                        } else if(status === 403 || status === 404) {
                            if(data.msg) {
                        	      $scope.errors.sites = [data.msg];
                            } else {
                                $scope.errors.sites = errrorServerMsg;
                            }
                        }
                        else {
                            $scope.errors.sites = errrorServerMsg;
                        }
                    });
            	}
            };

            //*******Login*******

            $scope.goToLogin = function() {
            	$location.path('/login').search('').search('rap', 'shopify');
            };

            //*******Register*******

            $scope.goToReg = function() {
            	$location.path('/register').search('').search('rap', 'shopify');
            };
        }
    }]);

