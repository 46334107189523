/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('NewBillingCtrl',
      ['$scope', 'BillingService', '$http', 'WaterJwt', 'AdvertiserService', 'LineItemService', '$filter', 'time', '$location',
        function($scope, BillingService, $http, WaterJwt, AdvertiserService, LineItemService, $filter, time, $location) {
          var that = this;
          var size = function(obj) {
              var size = 0, key;
              for (key in obj) {
                  if (obj.hasOwnProperty(key)) size++;
              }
              return size;
          }
          $scope.readyToMerge = {
            advertisers: false,
            billings: false,
            lineitems: false,
            money: false,
            isReady: function() {
              return this.advertisers && this.billings && this.lineitems && this.money;
            },
            pageState: "loading"
          };
          $scope.currency = $filter("currency");
          $scope.uppercase = $filter("uppercase");
          $scope.lowercase = $filter("lowercase");
          $scope.orderBy = $filter('orderBy');
          $scope.msg = {
            show: false,
            cls: "", //"alert-danger",
            text: ""
          };
          $scope.hiddenElements = 0;
          $scope.options = {
            search: $location.search().search || "",
            order_by: {
              column: "advertiser",
              reverse: false,
              index: "advertiser.name"
            }
          };
          $scope.columns = [];
          var addColumn = function(label, index) {
            $scope.columns.push({
              name: $scope.lowercase(label),
              label: label,
              index: index,
              sortable: index != undefined,
              isSortable: function() {
                return $scope.readyToMerge.pageState == 'completed' && this.sortable
              },
              isSorted: function(direction) {
                var isSorted = this.isSortable() && $scope.options.order_by.column == this.label;
                if (direction === undefined) {
                  return isSorted;
                } else {
                  return isSorted && direction == $scope.options.order_by.reverse;
                }
              },
              setOrder: function() {
                if (this.sortable) {
                  $scope.options.order_by.column = this.label;
                  if ($scope.options.order_by.index == this.index) {
                    $scope.options.order_by.reverse = !$scope.options.order_by.reverse;
                  } else {
                    $scope.options.order_by.index = this.index;
                    $scope.options.order_by.reverse = true;
                  }

                  $scope.doSearch();
                }
              }
            })
          };

          addColumn("Advertiser", "advertiser.name");
          addColumn("Type", "priceType");
          addColumn("Price");
          addColumn("Imps", "sums.imps");
          addColumn("Convs", "sums.conversions");
          addColumn("Media Costs", "sums.total_cost");
          addColumn("Adv Costs", "sums.calculated_fee");
          addColumn("Credit Limit", "advertiser.credit_limit");
          addColumn("Balance Owned", "advertiser.budget");
          addColumn("Spend Left", "advertiser.spend_left");

          var isReportHidden = function (report) {
            if (report.line_items.length == 0) {
              return true;
            }
            var search = $scope.options.search;

            if ( search ) {
              return ! report.advertiser.name.toLowerCase().includes(search.toLowerCase());
            }

            return false;
          };

          $scope.merge = function(advertiser_id){
            if($scope.readyToMerge.isReady()) {
              angular.forEach($scope.advertisers, function(advertiser) {
                $scope.reports[advertiser.id] = {
                  advertiser: advertiser,
                  sums: {
                    imps: 0,
                    conversions: 0,
                    total_cost: 0,
                    calculated_fee: 0
                  },
                  line_items: [],
                  showLineItems: advertiser_id == advertiser.id
                }
              });

              angular.forEach($scope.billings, function(billing) {
                var line_item = $scope.lineitems[billing.line_item_id];
                var advertiser = $scope.reports[billing.advertiser_id];
                var money = $scope.money[billing.advertiser_id];
                var lineitem_costs = money ? money.lineitems[billing.line_item_id] : 0;
                if (line_item && advertiser) {
                  billing.name = line_item.name;
                  billing.revenue_value = line_item.revenue_value;
                  $scope.reports[billing.advertiser_id].line_items.push(billing);
                  billing.lineitem_costs = lineitem_costs ? lineitem_costs.value : 0;
                } else {
                  if (!advertiser) {
                    console.error("Advertiser with id "+ billing.advertiser_id + "does not exists!");
                  }
                  if (!line_item) {
                    console.error("Line Item with id "+ billing.line_item_id + "does not exists!");
                  }
                }
              });

              angular.forEach($scope.reports, function(report) {
                angular.forEach(report.line_items, function(line_item){
                  var money = $scope.money[report.advertiser.id];
                  report.sums.imps += line_item.imps;
                  report.sums.conversions += line_item.conversions;
                  report.sums.total_cost += line_item.total_cost;
                  report.sums.calculated_fee += line_item.calculated_fee;
                  report.priceType = $scope.showPriceType(report);
                  report.advertiser.money = money ? Number(money.money.value) : 0;
                  report.advertiser.budget = money ? Number(money.budget.value) : 0;
                  report.advertiser.spend_left = money ? Number(money.spend_left.value) : 0;
                  report.advertiser.credit_limit = money ? Number(money.credit_limit.value) : 0;
                });
                $scope.reportsList.push(report);
              });

              $scope.doSearch();
              $scope.$apply();
            }
          };

          $scope.reloadData = function(startDate, endDate, advertiser_id) {
            $scope.billings = [];
            $scope.reports = {};
            $scope.reportsList = [];
            $scope.readyToMerge.pageState = "loading";

            $scope.readyToMerge.billings = false;

            $scope.startDate = startDate;
            $scope.endDate = endDate;

            var params = {
              from_date: time.getBackendDate($scope.startDate),
              to_date: time.getBackendDate($scope.endDate)
            };

            BillingService.fetchBilling(params).then(function(billings){
              console.log("Billings: [done]");
              $scope.billings = billings;
              $scope.readyToMerge.billings = true;
              $scope.merge(advertiser_id);
            });

            BillingService.fetchMoney().then(function(money){
              console.log("Money: [done]");
              $scope.money = money;
              $scope.readyToMerge.money = true;
              $scope.merge(advertiser_id);
            });

            AdvertiserService.fetchAdvertisers().then(function(advertisers){
              console.log("Advertisers: [done]");
              $scope.advertisers = advertisers;
              $scope.readyToMerge.advertisers = true;
              $scope.merge(advertiser_id);
            });

            LineItemService.fetchLineItems().then(function(lineitems){
              console.log("Line Items: [done]");
              $scope.lineitems = lineitems;
              $scope.readyToMerge.lineitems = true;
              $scope.merge(advertiser_id);
            });
          };

          $scope.showPriceType = function(report) {
            var isCpa = false,
              isCpm = false,
              isOther = false;
            angular.forEach(report.line_items, function(line_item) {
              if (line_item.revenue_type == "cpa") {
                isCpa = true;
              } else if (line_item.revenue_type == "cpm") {
                isCpm = true;
              } else {
                isOther = true;
              }
            });

            if (isOther){
              return "MIXED";
            } else if (isCpa && isCpm) {
              return "CPA / CPM";
            } else if (isCpa) {
              return "CPA";
            } else if (isCpm) {
              return "CPM"
            } else {
              return "-";
            }
          };

          $scope.getPriceTypeClass = function(report) {
            var isCpa = false,
              isCpm = false;
            angular.forEach(report.line_items, function(line_item) {
              if (line_item.revenue_type == "cpa") {
                isCpa = true;
              } else if (line_item.revenue_type == "cpm") {
                isCpm = true;
              }
            });

            if (isCpa && isCpm) {
              return "cpacpm";
            } else if (isCpa) {
              return "cpa";
            } else if (isCpm) {
              return "cpm"
            } else {
              return "";
            }
          };

          $scope.doSearch = function() {
            $scope.hiddenElements = 0;
            if($scope.options.search) {
              $location.search({search: $scope.options.search});
            } else {
              $location.search({});
            }

            angular.forEach($scope.reports, function(report) {
              if ( isReportHidden(report) ) {
                report.isHidden = true;
                $scope.hiddenElements += 1;
              } else {
                report.isHidden = false;
              }
            });

            var hasData = $scope.hiddenElements < size($scope.reports);

            if (hasData) {
              $scope.readyToMerge.pageState = "completed";
            } else {
              $scope.readyToMerge.pageState = "nodata";
            }
          }

          $scope.clearSearch = function() {
            $scope.options.search = "";
            $scope.doSearch();
          }

          var yesterday = time.getUtcYesterdayDate();
          $scope.reloadData(yesterday, yesterday);

          $scope.$on('newbiling-list-reload', function(event, data){
            $scope.readyToMerge.billings = false;
            $scope.readyToMerge.lineitems = false;
            $scope.reloadData($scope.startDate, $scope.endDate, data.advertiser_id);
          });

        }]);

