/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('CreativesReportCtrl',
      ['$scope', 'CreativeList', 'time', 'Tables', 'WaterJwt',
        function($scope, CreativeList, time, Tables, WaterJwt) {
            var table = Tables.create($scope, CreativeList.getCreativeList);

            table.createColumn("creative_name", "Creative Name");
            table.createColumn("media_url", "Image", function(row){
                return {
                    media_url: row[this.key],
                    width: row["width"],
                    height: row["height"]
                }
            }, false);
            table.createColumn("size", "Size", function(row){
                if (row["width"] || row["height"]) {
                    return row["width"] + "px x " + row["height"] + "px";
                } else {
                    return "";
                }
            }, false);
            table.createColumn("sum_imps", "Imps")
            table.createColumn("sum_clicks", "Clicks")
            table.createColumn("ctr", "CTR%");
            table.createColumn("sum_total_conversions", "Total Convs");
            table.createColumn("sum_conversions", "Convs");
            table.createColumn("sum_visitor_conversions", "Hivewyre Visitors");
            table.createColumn("revenue", "Spend");
            table.createColumn("conversion_revenue", "Convs Revenue");
            table.createColumn("roas", "ROAS");
            table.createColumn("cpa", "CPA");

            $scope.excel_url = CreativeList.excel_url;
            $scope.getBackendDate = time.getBackendDate;
        }
    ]);

