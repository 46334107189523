/*** IMPORTS FROM imports-loader ***/


angular
  .module('adhesiveApp')
  .component(
    'selectedSiteCtrl', {
      controller: selectedSiteCtrl,
      template: require('$views/selected-site.html').default,
      controllerAs: 'vm'
    }
  );

selectedSiteCtrl.$inject = ['$routeParams', '$location', 'TagService', 'userServ'];
function selectedSiteCtrl($routeParams, $location, TagService, userServ) {
  var vm = this;
  vm.siteList = [];
  vm.selectedSite = null;

  vm.editSite = editSite;
  vm.selectSite = selectSite;

  vm.$onInit = function () {
    if(userServ.isRegistered() && !userServ.isAdmin()) {
      getSitesList();
    }
  };

  function editSite () {
    $location.path('/sites/' + vm.selectedSite).search([]);
  }

  function selectSite (siteId) {
    vm.selectedSite = siteId;
    if(siteId === 'newSite') {
      $location.path('/sites/add');
    } else {
      $location.search('site-id', siteId);
    }
  }

  function getSitesList () {
    TagService.getTags($routeParams.user_id).then(function(resp){
      vm.sitesList = resp.data;
      if(userServ.isSalesman()) {
        vm.sitesList.forEach(function (obj, index) {
          obj.name = 'Site ' + (index + 1);
        });
      }
      vm.sitesList.push({
        name: 'Add New Site',
        id: 'newSite'
      });

      selectSite($location.search()['site-id'] || vm.sitesList[0].id);
    });
  }
}

