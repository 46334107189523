/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
  .controller('RegisterCtrl', ['$scope', '$http', '$location', 'SegmentList', 'FormMessages', 'userServ', '$rootScope', 'apiPrefixUrl',
    function($scope, $http, $location, SegmentList, FormMessages, userServ, $rootScope, apiPrefixUrl) {
      $scope.errors = {
        all: [],
        segment: [],
        email: [],
        password: [],
        confPass: []
      };
      $scope.regForm = {};
      $scope.registered = false;
      $scope.step = 0;
      $scope.token = $location.search().token;
      if($scope.token){
        $scope.step = 1;
      }
      $scope.shopifyReg = ($location.search().rap === 'shopify');
      if($scope.shopifyReg) {
        var shopifyData = {};
        if(localStorage.shopifyUser) {
          shopifyData = JSON.parse(localStorage.shopifyUser);
          $scope.regForm.email = shopifyData.email;
          $scope.regForm.site = shopifyData.domain;
        }
      }
      SegmentList.getSegmentList().then(function(resp) {
        $scope.segmentList = resp.data;
      }, function() {
        $scope.errors.all.push('There has been problem with server, please try again later');
      });

      $scope.register = function() {
        $scope.errors = {
          all: [],
          segment: [],
          email: [],
          password: [],
          confPass: []
        };
        if ($scope.regForm.registerForm.$invalid) {
          $scope.errors = FormMessages.prepErrArr($scope.regForm.registerForm);
          return false;
        }

        if($scope.shopifyReg) {
          $http.post('/shopify/accounts/create?shop=' + shopifyData.shop, {
              email: $scope.regForm.email,
              password: $scope.regForm.password,
              website: {
                domain: $scope.regForm.site,
                segment: $scope.regForm.selectedSegment
              }
            }
            ).then(function(resp) {
              userServ.register(resp.data);
              $rootScope.$broadcast('login-success');
              $location.path('/tags').search('').search('rap', 'shopify');
            }, function(resp) {
              var data = resp.data;
              var status = resp.status;
              if(status === 401) {
                $location.path('/shopify/landingpage').search('');
              } else {
                if (typeof data === typeof {} || typeof data === typeof []) {
                  if (data.msg) {
                    $scope.errors['all'] = [data.msg];
                  } else {
                    $scope.errors = data.errors;
                  }
                } else {
                  $scope.errors['all'].push('Server communication error');
                }
              }
            });
        } else {
          $http.post(apiPrefixUrl + '/accounts/accounts', {
              email: $scope.regForm.email,
              password: $scope.regForm.password,
              website: {
                name: $scope.regForm.site,
                segment: $scope.regForm.selectedSegment
              }
            })
            .then(function(resp) {
              $scope.registered = true;
            }, function(resp) {
              var data = resp.data;
              if (typeof data === typeof {} || typeof data === typeof []) {
                if (data.msg) {
                  $scope.errors['all'].push(data.msg);
                } else {
                  $scope.errors = data;
                }
              } else {
                $scope.errors['all'].push('Server communication error');
              }
            });
        }
      };
    }
  ]);

