/*** IMPORTS FROM imports-loader ***/


import _ from "underscore";

angular.module('adhesiveApp').controller('PaymentformCtrl', ['$scope', 'Card', function ($scope, Card) {
    var current_year = (new Date()).getFullYear(),
        stdErrorMsg = 'Server communication error';

    $scope.months = _.map(_.range(1, 13), String);
    $scope.years = _.map(_.range(current_year, current_year + 8), String);
    $scope.send = false;
    $scope.errors = null;
    $scope.$on('popover-opened', function(){
        $scope.send = false;
    });

    if($scope.$parent.card) {
        $scope.card = angular.copy($scope.$parent.card);
        $scope.card.card.number = '';
        $scope.card.card.expiration_year = '';
        $scope.card.card.expiration_month = '';
        $scope.card.card.code = '';
        delete $scope.card.recurring;
        delete $scope.card.card.type;
    }
    else {
        $scope.card = new Card({
            "card": {
                "number": "",
                "expiration_year": "",
                "expiration_month": "",
                "code": ""
            },
            "billing": {
                "first_name": "",
                "last_name": "",
                "company": "",
                "address": "",
                "city": "",
                "state": "",
                "zip": "",
                "country": ""
            }
        });
    }

    $scope.getError = function(path, place){
        var msg = '';
        place = place || $scope.errors;
        if(!place){
            return msg;
        }

        // Stop condition for recursion
        if(angular.isString(place)){
            return place;
        }

        else if(angular.isArray(place)){
            angular.forEach(place, function(v){
                msg += $scope.getError(path, v);
            });
        }

        else if(angular.isObject(place)){
            path = path.split('.');
            angular.forEach(place, function(v,k){
                if(path[0] == k){
                    msg += $scope.getError(path.slice(1).join('.'), v);
                }
            });
        }

        return msg;
    }

    $scope.submit = function() {
        $scope.send = true;
        $scope.errors = null;
        $scope.payment_form.$setPristine();
        $scope.msg = ['Saving data...', 'alert-info'];

        if($scope.payment_form.$valid) {
            if($scope.card.payment_id) {
                $scope.card.$update(function(){
                    $scope.$emit('msg:deposit', 'Payment updated', 'alert-success');
                    $scope.$emit('deposit:cards-reload');
                    $scope.closePopover();
                }, function(data){
                    $scope.msg = [stdErrorMsg, 'alert-danger'];
                    if(angular.isObject(data.data)){
                        if(data.data.message){
                            $scope.msg = [data.data.message.join(' '), 'alert-danger'];
                        }

                        if(data.data.errors){
                            $scope.errors = data.data.errors;
                        }
                    }
                });
            }
            else {
                $scope.card.$save(function(){
                    $scope.$emit('msg:deposit', 'New payment added', 'alert-success');
                    $scope.$emit('deposit:cards-reload');
                    $scope.closePopover();
                }, function(data){
                    $scope.msg = [stdErrorMsg, 'alert-danger'];
                    if(angular.isObject(data.data)){
                        if(data.data.message){
                            $scope.msg = [data.data.message.join(' '), 'alert-danger'];
                        }

                        if(data.data.errors){
                            $scope.errors = data.data.errors;
                        }
                    }
                });
            }
        }
        else {
            $scope.msg = ['Form is invalid. Please check that all fields are filled out.', 'alert-danger'];
        }
    };
}]);

