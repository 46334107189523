/*** IMPORTS FROM imports-loader ***/


'use strict';

import env from "@/app/env.js";

angular.module('adhesiveApp')
    .controller('TopmenuCtrl', ['$scope', 'userServ', '$location', '$http', '$rootScope', '$routeParams', 'TagService', '$route', 'apiPrefixUrl', 'UserService',
        function($scope, userServ, $location, $http, $rootScope, $routeParams, TagService, $route, apiPrefixUrl, UserService) {
            $scope.env = {platform: env.platform};
            var self = this;

            var getSitesList,
                checkIfUserIsLogged,
                body, lowResulutionBgBugfix, i,
                userlogged = !userServ.isRegistered() && ($location.path() != '/login' && $location.path() != '/register' && $location.path() != '/reset_password' && $location.path() !== '/shopify/landingpage');
            checkIfUserIsLogged = function() {
                lowResulutionBgBugfix();
                if(userlogged){
                    $location.path('/login');
                }
            };
            $scope.$route = $route;
            $scope.$watch(function() {
                return $location.path();
                }, function() {
                $scope.locat = $location.path();
                if(userServ.isSalesman()) {
                    var salesmanAvailablePages = [
                      '/',
                      '/campaigns',
                      '/admin',
                      '/admin/revenue',
                      '/admin/segments',
                      '/admin/health-report',
                      '/admin/tags-monitoring',
                      '/old/admin/revenue',
                    ];

                    if(salesmanAvailablePages.indexOf($scope.locat) === -1) {
                        $location.path('/admin');
                    }
                }
                var toolsPages = [
                  '/admin/custom_segments',
                  '/admin/forecasting',
                  '/admin/reports-by-sales-rep',
                  '/admin/monthly-reports-by-adv',
                  '/admin/missed-cap-report',
                  '/admin/creative-reports',
                  '/admin/tags-monitoring',
                  '/admin/newbilling',
                ];


                $scope.toolsMenu.hightlight = toolsPages.indexOf($scope.locat) > -1;
                $scope.toolsMenu.isDisplayed = false;

                $scope.oldMenu.links =  [
                  {'url': '/old/admin/revenue', 'name': 'Revenue Report'},
                  {'url': '/old/admin/reports-by-sales-rep', 'name': 'Reports by sales rep'},
                  {'url': '/old/admin/monthly-reports-by-adv', 'name': 'Monthly reports by Advertiser'},
                ];

                $scope.oldMenu.isDisplayed = false;
            });

            $scope.isCampaignsPage = function(){
                return $location.path() == '/campaigns';
            };

            $scope.isInsightsPage = function(){
                return $location.path() == '/';
            };

            $scope.isShopifyLandingPage = function(){
                return ($location.path() === '/shopify/landingpage') || $location.search().rap;
            };

            lowResulutionBgBugfix = function() { //low resolution bg fast bugfix
                body = document.getElementsByTagName('body')[0]
                if(userServ.isAdvertiser() || (userServ.isSalesman() && $scope.isCampaignsPage()) || !userServ.isRegistered()) {
                    body.style.backgroundColor='#292929';
                } else {
                    body.style.backgroundColor='#ffffff';
                }
            };

            checkIfUserIsLogged();
            $scope.$on('$locationChangeSuccess', checkIfUserIsLogged);

            $scope.user = userServ;

            $scope.salesmanReports = function(){
                return userServ.isSalesman() && $routeParams.user_id;
            };

            $scope.$watch(function() {
                return $routeParams.user_id;
              }, function() {
                if($scope.salesmanReports && $routeParams.user_id) {
                    $scope.salesmanReportsUserId = '?user_id=' + $routeParams.user_id;
                    userServ.setAsAdminFromAdminPanel();
                }
                else {
                    $scope.salesmanReportsUserId = '';
                }
            });

            $scope.isHomePage = function(){
                return $location.path() == '/';
            };

            $scope.logout = function(){
                $http.post(apiPrefixUrl + '/logout')
                    .then(function(){
                        window.localStorage.clear();
                        $location.url($location.path());
                        $rootScope.$broadcast('loginRequired');
                    }, function(){
                        alert('Server communication error');
                    });
            };

            $scope.toolsMenu = {};
            $scope.toolsMenu.isDisplayed = false;
            $scope.toolsMenu.show = function() {
                $scope.toolsMenu.isDisplayed = !$scope.toolsMenu.isDisplayed;
                $scope.oldMenu.isDisplayed = false;
            };

            $scope.oldMenu = {};
            $scope.oldMenu.isDisplayed = false;
            $scope.oldMenu.show = function() {
                $scope.oldMenu.isDisplayed = !$scope.oldMenu.isDisplayed;
                $scope.toolsMenu.isDisplayed = false;
            };

            $scope.notifications = {
                loaded: false,
                allNotificationsCounter: 0,
                unreadedNotificationsCounter: 0,
                unreadedImportantNotifications: 0,
            };

            const refreshNotificationPeriod = 1000;
            let refreshNotification = () => {
                UserService.getNotifications().then((resp) =>{
                    let allNotificationsCounter = 0,
                       unreadedNotificationsCounter = 0,
                       unreadedImportantNotifications = 0,
                       notifications = resp.notifications || [];

                    notifications.forEach((notification) => {
                       allNotificationsCounter++;
                       if(!notification.accepntance_at) {
                           unreadedNotificationsCounter++;
                       }
                       if(!notification.accepntance_at && notification.typename != 'info') {
                           unreadedImportantNotifications++;
                       }
                    });
                    let newNotifications = {
                        loaded: true,
                        allNotificationsCounter,
                        unreadedNotificationsCounter,
                        unreadedImportantNotifications
                    };
                    if(newNotifications != $scope.notifications) {
                        $scope.notifications = newNotifications;
                        $scope.$apply();
                    }
                    setTimeout(refreshNotification, refreshNotificationPeriod);
                });
            };
            setTimeout(refreshNotification, 0);
        }]);

