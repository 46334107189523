/*** IMPORTS FROM imports-loader ***/


'use strict';
import "flot.tooltip";
import "bootstrap-select";

angular
  .module('adhesiveApp')
  .controller('StatisticCtrl', StatisticCtrl);

StatisticCtrl.$inject = [
  '$scope', '$http', 'userServ', 'time', '$filter', 'userServHelper',
  '$routeParams', '$location', 'PRICING_MODELS', 'utils', 'apiPrefixUrl',
  'ReportSummary', 'FeaturesService', ];
function StatisticCtrl (
      $scope, $http, userServ, time, $filter, userServHelper, $routeParams,
      $location, PRICING_MODELS, utils, apiPrefixUrl, ReportSummary,
      FeaturesService) {

    var self = this;
    var requestsParams = {
        reportsUserId: $routeParams.user_id || userServ.getId()
    };
    var addTooltipContent = function(label, xval, yval) {
        var localDate = new Date(xval),
            utcTimestamp = localDate.getTime() + 60000 * localDate.getTimezoneOffset();
        return '<span>' + label + '</span>: ' + $filter('filter')($scope.tabs, {'label':label})[0].formatter(yval) + '<br /><span>Date:</span> ' + $filter('date')(utcTimestamp, 'mediumDate');
    };
    var EXCEL_FILE_URL = apiPrefixUrl + '/appnexus/report/export_to_excel';


    FeaturesService.getFeatures().then((features) => {
        if(features["visitor_convs"]) {
            $scope.tabs.splice(1, 0, {
                label: 'Visitor Conv',
                code: 'visitor_convs',
                formatter: angular.identity,
                noCapitalize: false
            });
        }
        if(features["cost_per_visitor"]) {
            $scope.tabs.splice(1, 0, {
                label: 'Cost per visitor',
                code: 'cpv',
                formatter: $filter('currency'),
                valuePrefix: '$',
                noCapitalize: false
            });

        }
    });

    $scope.user = userServ;
    $scope.chartError = false;
    $scope.campaignsChart = {};
    $scope.tabs = [
        {
            label: 'Conv',
            code: 'purchase_convs',
            formatter: angular.identity,
            noCapitalize: false
        },
        {
            label: 'Imps',
            code: 'imps',
            formatter: angular.identity,
            noCapitalize: false
        },
        {
            label: 'Clicks',
            code: 'clicks',
            formatter: angular.identity,
            noCapitalize: false
        },
        {
            yAxis_formatter: function() {
                return this.value + '%';
            },
            label: 'CTR',
            code: 'ctr',
            formatter: $filter('percentage'),
            valueSuffix: '%',
            valueFormatter: function(v){
                return Number((v * 100).toFixed(3));
            },
            noCapitalize: false
        },
        {
            label: 'Avg CPC',
            code: 'cpc',
            formatter: $filter('currency'),
            valuePrefix: '$',
            noCapitalize: false
        },
        {
            label: 'Total conv value',
            code: 'total_revenue',
            formatter: $filter('currency'),
            valuePrefix: '$',
            noCapitalize: false
        },
        {
            label: 'Avg order value',
            code: 'avg_order_value',
            formatter: $filter('currency'),
            valuePrefix: '$',
            noCapitalize: false
        },
        {
            label: 'Spend for period',
            code: 'spend',
            formatter: $filter('currency'),
            valuePrefix: '$',
            noCapitalize: false
        },
        {
            label: 'ROAS',
            code: 'roas',
            formatter: function(value) {
                return parseFloat(value) != NaN ? parseFloat(value).toFixed(2) +":1" : '-';
            },
            noCapitalize: false
        },
        {
            label: 'eCPA',
            code: 'ecpa',
            formatter: function(value) {
                return parseFloat(value) != NaN ? parseFloat(value).toFixed(2) : '-';
            },
            noCapitalize: true
        },
        {
            label: 'Cross Device Convs',
            code: 'cross_device_convs',
            formatter: function(value) {
                return angular.isNumber(value) ? value.toFixed(0) : '-';
            },
            noCapitalize: true
        },
    ];
    $scope.dateRangeId = 'last_7_days';
    $scope.site = '';
    $scope.activeTab = $scope.tabs[0];

    $scope.campaignsChart.options = {
            xaxes: [
              {
                mode: 'time',
                ticks: 12,
                minTickSize: [1, 'day'],
                timeformat: '%b %d'
              }
            ],
            yaxes: [
              {min: 0},
              {
                position: 'right',
                min: 0,
                alignTicksWithAxis: 1
               }
            ],
            grid : {
                show : true,
                margin: {
                    top: 35,
                    left: 10,
                    bottom: 0,
                    right: 30
                },
                borderWidth: 0,
                labelMargin: 40,
                color: '#ffffff',
                hoverable: true
            },
            series: {
                lines: {
                    show: true,
                    lineWidth: 3
                },
                points: {
                    show: true
                }
            },
            color: '#ffc200',
            shadowSize: 0,
            tooltip: {
                show: true,
                cssClass: 'flot-tooltip-campaigns',
                content: function(label, xval, yval) {return addTooltipContent(label, xval, yval);}
            },
            legend: {
              show: false,
              backgroundColor: '#252525',
              backgroundOpacity: 0.35,
              position: 'ne',
              noColumns: 2,
              margin: [-30, -25]
            }
        };

    $scope.campaignsChart.data = [
      {
        data: [],
      },
      {
        data: [],
        yaxis: 2
      }
    ];

    $scope.updateChartDataForNewDate = function(fromCalendar, startDate, endDate) {
        $scope.startDate = startDate;
        $scope.endDate = endDate;
        if(fromCalendar) {
            $scope.dateRangeId = '';
        }
        requestsParams.startDate = time.getBackendDate($scope.startDate);
        requestsParams.endDate = time.getBackendDate($scope.endDate);
        $scope.getCampaignsList();
        $scope.getChartsData();
        $scope.getSettingsData();
    };

    $scope.getCampaignsList = function() {
        var defaultSite = {
            key: '',
            value: 'All campaigns'
        };
        $scope.site = '';
        if (!$scope.sites){
            $scope.sites = [defaultSite];
            $scope.defaultLength = $scope.sites.length;
        }
        var params = {
            from_date: requestsParams.startDate,
            to_date: requestsParams.endDate,
            user_id: requestsParams.reportsUserId
        };
        $http.get(apiPrefixUrl + '/appnexus/report/sites', {params: params}).then(function(resp) {
                $scope.sites.splice($scope.defaultLength, $scope.sites.length);
                var min = 0;
                var max = 100000;
                // randomizing key in this select is a workaround. If the same key would be used, the
                // selectpicker whould hang
                resp.data.forEach(row => {
                    var randint = Math.floor(Math.random() * (max - min)) + min;
                    row.key = row.key +'_'+ randint;
                    $scope.sites.push(row);
                });
            });
    };

    $scope.changeCampaign = function() {
        $scope.getChartsData();
    };

    $scope.addSecondAxis = function() {
      $scope.campaignsChart.data[1].show = true;
      $scope.campaignsChart.options.yaxes[1].show = true;
      $scope.campaignsChart.options.legend.show = true;
    }

    $scope.removeSecondAxis = function() {
      $scope.campaignsChart.data[1].show = false;
      $scope.campaignsChart.options.yaxes[1].show = false;
      $scope.campaignsChart.options.legend.show = false;
    }

    $scope.drawCharts = function(chartData, shouldCompare) {
      var axis = $scope.campaignsChart.data[0],
          secondAxis = $scope.campaignsChart.data[1];
      axis.data = [];
      secondAxis.data = [];
      axis.label = $scope.activeTab.label;

      if (shouldCompare) {
        $scope.addSecondAxis();
        secondAxis.label = $scope.axisToCompare.label;
      } else {
        $scope.removeSecondAxis();
      }

      for(var i = 0; i < chartData.length; i++) {
          var date = new Date(chartData[i].day).getTime();
          axis.data.push([date, chartData[i][$scope.activeTab.code]]);
          if (shouldCompare) {
            secondAxis.data.push([date, chartData[i][$scope.axisToCompare.code]]);
          }
      }
    };

    $scope.getChartsData = function() {
        var params = {
            from_date: requestsParams.startDate,
            to_date: requestsParams.endDate,
            user_id: requestsParams.reportsUserId
        }, reportParams, summaryParams, shouldCompare;
        if($scope.site) {
            // randomizing key in this select is a workaround. If the same key would be used, the
            // selectpicker whould hang
            params.line_item = $scope.site.split("_")[0];
        }

        reportParams = angular.copy(params);
        reportParams.fields = $scope.activeTab.code;

        shouldCompare = $scope.axisToCompare && $scope.axisToCompare.code != $scope.activeTab.code;
        if (shouldCompare) {
          reportParams.fields += ',' + $scope.axisToCompare.code;
        }

        $http
          .get(apiPrefixUrl + '/appnexus/report', {params: reportParams})
          .then(function(resp) {
                $scope.chartError = false;
                $scope.drawCharts(resp.data, shouldCompare);
            }, function() {
                $scope.chartError = true;
            }
        );

        summaryParams = angular.copy(params);
        ReportSummary.getReportSummary(summaryParams).then(
            function(resp) {
                $scope.statistic = resp.data;
                $scope.$digest();
            },
            function(resp) { $scope.chartError = true; }
        );
    };

    $scope.getSettingsData = function() {
        var params = {
            from_date: requestsParams.startDate,
            to_date: requestsParams.endDate,
            user_id: requestsParams.reportsUserId
        };
        $http
          .get(apiPrefixUrl + '/appnexus/campaigns/settings', {params: params})
          .then(function(resp) { $scope.settings = resp.data; });
    };

    $scope.setActiveTab = function(tab) {
        $scope.activeTab = tab;
        $scope.campaignsChart.options.tooltip.content = function(label, xval, yval) {return addTooltipContent(label, xval, yval);};
        $scope.getChartsData();
    };

    $scope.isActiveTab = function(tab) {
        return $scope.activeTab === tab;
    };

    $scope.pricingModel = function(){
        if(!$scope.settings || !$scope.settings.advertiser_profile) {
            return null;
        }

        var id = $scope.settings.advertiser_profile.advertiser_pricing_model;

        return PRICING_MODELS[id];
    };

    $scope.haveSettingsData = function(){
        var statistics = $scope.statistic && ($scope.statistic.purchase_convs
            || $scope.statistic.imps || $scope.statistic.clicks
            || $scope.statistic.ctr || $scope.statistic.cpc || $scope.statistic.visitor_convs);

        var settings = $scope.settings && $scope.settings.advertiser_profile.line_items.length;

        return statistics && settings;
    };

    $scope.getExportToExcelURL = function(){
        return utils.getUrlWithSearchParams(EXCEL_FILE_URL,
            {from_date: requestsParams.startDate, to_date: requestsParams.endDate, user_id: requestsParams.reportsUserId});
    };

    $scope.showDetails = false;

    $scope.setVisibleDetails = function(row) {
        if($scope.showDetails === row) {
            $scope.showDetails = false;
        } else {
            $scope.showDetails = row;
        }
    };

    $scope.init = function() {
        userServHelper.getBalance(function(balance) {
            $scope.balance = balance;
        }, {user_id: requestsParams.reportsUserId});

    };

    $scope.addAxisToCompare = function() {
        $scope.getChartsData();
    };

    if(userServ.isAdvertiser() || ($routeParams.user_id && userServ.isSalesman())) {
        $scope.init();
    }
    else {
        $location.path('/admin');
    }
}

