/*** IMPORTS FROM imports-loader ***/


'use strict';

angular
  .module('adhesiveApp')
  .controller('BillingCtrl', BillingCtrl);

BillingCtrl.$inject = [
  '$scope', '$http', 'userServ', '$rootScope', '$filter', 'apiPrefixUrl',
  'utils', 'userServHelper', 'time'];

function BillingCtrl (
              $scope, $http, userServ, $rootScope, $filter, apiPrefixUrl,
              utils, userServHelper, time){

            var self = this;
            var requestsParams = {},
                EXCEL_FILE_URL = apiPrefixUrl + '/billing/transactions/export_to_excel';

            $scope.dateRangeId = 'last_7_days';
            $scope.transactions = [];
            $scope.errors = {
                transactions: null
            };
            $scope.userServ = userServ;

            $scope.updateTableDataForNewDate = function(fromCalendar, startDate, endDate) {
                $scope.startDate = startDate;
                $scope.endDate = endDate;
                if(fromCalendar) {
                    $scope.dateRangeId = '';
                }

                var getDate = time.getBackendDate;
                requestsParams.startDate = getDate($scope.startDate);
                requestsParams.endDate = getDate($scope.endDate);
                var params = {
                    from_date: requestsParams.startDate,
                    to_date: requestsParams.endDate
                };
                $http
                  .get(apiPrefixUrl + '/billing/transactions', {params: params})
                  .then(function(resp) {
                    var data = resp.data;
                    $scope.errors.transactions = null;
                    $scope.transactions = data;
                }, function(resp) {
                    var data = resp.data;
                    if(typeof data === 'object' && data.msg){
                        $scope.errors.transactions = data.msg;
                    }
                    else {
                        $scope.errors.transactions = 'Server communication error';
                    }
                });

            };

            $scope.getExportToExcelURL = function(){
                return utils.getUrlWithSearchParams(EXCEL_FILE_URL,
                    {from_date: requestsParams.startDate, to_date: requestsParams.endDate});
            };

            userServHelper.getBalance(function(balance){
                $scope.balance = balance
            });
}

