/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .controller('AnalyticsCtrl',
      ['$scope', '$http', 'userServ', '$routeParams', '$location', 'awarenessRatingService', 'analytics7daysService', 'apiPrefixUrl',
        function($scope, $http, userServ, $routeParams, $location, awarenessRatingService, analytics7daysService, apiPrefixUrl) {
            $scope.email_verified = $location.search().email_verified;
            $scope.showPreloader = true;
            init();

            function init () {
              var isFromAdminPanel = userServ.isFromAdminPanel();
              var isSalesman = $routeParams.user_id && userServ.isSalesman();
              if(isFromAdminPanel || isSalesman) {
                if ($scope.email_verified){
                  $location.url('/account').search({email_verified: $scope.email_verified});
                } else {
                  getData();
                }
              } else if (userServ.isAdvertiser()) {
                $location.path('/campaigns');
              } else {
                $location.path('/admin');
              }
            }

            function getData () {
              var selectedSite = $location.search()['site-id'];
              if (!selectedSite) {
                return;
              }
              var url = apiPrefixUrl + '/analytics/report/' + selectedSite;
              if($routeParams.user_id) {
                url += '?user_id=' + $routeParams.user_id;
              }
              $http.get(url).then(function(resp) {
                var data = resp.data;
                drawData(data);
                $scope.analyticsData = data;
                $scope.showPreloader = false;
              });
            }

            function drawData (analyticsData) {
                $scope.awarenessRatingPie = awarenessRatingService.setPieChartData(analyticsData.awareness_rating.current.value);
                $scope.awarenessRatingMsg = awarenessRatingService.setRankingPositionMsg(analyticsData.awareness_rating.current.position);
                $scope.awarenessRatingTrend = awarenessRatingService.setTrendChartData(analyticsData.awareness_rating.trend);
                $scope.winPercentageTrend = analytics7daysService.setChartData(analyticsData.win_percentage.trend);
                $scope.winPercentageMsg = analytics7daysService.setRankingPositionMsg(analyticsData.win_percentage.current.position);
                $scope.conversionRateTrend = analytics7daysService.setChartData(analyticsData.conversion_rate.trend);
                $scope.conversionRateMsg = analytics7daysService.setRankingPositionMsg(analyticsData.conversion_rate.current.position);
                $scope.revenuePerVisitorMsg = analytics7daysService.setRankingPositionMsg(analyticsData.revenue_per_visitor.current.position);
                $scope.conversionValueMsg = analytics7daysService.setRankingPositionMsg(analyticsData.conversion_value.current.position);
            }

        }]);

