/*** IMPORTS FROM imports-loader ***/


angular.module('adhesiveApp')
  .controller('NotificationsCtrl', ['$scope', 'UserService', '$filter', 'time', '$location', 'TableTwo',
  function($scope, UserService, $filter, time, $location, TableTwo) {
    let params = $location.search(),
      dateFormat = $filter('date');

    $scope.options = {
      order_by: {
        reverse: params.direction ? params.direction == "desc" : false,
        index: params.sort_by || "created_at"
      }
    };

    $scope.merge = {
      state: {
        failed: false,
        notifications: false,
        completed: false,
      },
      rawData: {
        notifications: null,
        failedMessage: null,
      },
      reset: () => {
        let self = $scope.merge;
        self.state.notifications = false;
        self.state.completed = false;
        self.rawData.notifications = null;
      },
      start: () => {
        let self = $scope.merge;
        self.reset();
        UserService.forceRefreshNotifications($scope.page).then(
          self.setNotifications,
          (errorMessage) => {
            self.state.failed = true;
            self.rawData.failedMessage = errorMessage;
            $scope.$apply();
          }
        );
      },
      isReady: () => {
        let state = $scope.merge.state;
        return state.notifications && state.completed;
      },
      setNotifications: (resp) => {
        let self = $scope.merge;
        self.state.notifications = true;
        self.rawData.notifications = resp.notifications;
        $scope.page.init(resp.pageinfo.page, resp.pageinfo.max_page, resp.pageinfo.pagesize);
        self.parseRawData();
      },
      parseRawData: () => {
        let self = $scope.merge;
        if (self.state.notifications) {
          self.state.completed = true;
          $scope.$apply();
        };
      }
    };

    $scope.page = {
      maxPage: 0,
      currentPage: 1,
      pageSize: 0,
      isReady: false,
      prevPage: () => {
        let self = $scope.page;
        if(self.isFirstEnabled()){
          self.currentPage--;
          $scope.merge.start();
        }
      },
      nextPage: () => {
        let self = $scope.page;
        if(self.isLastEnabled()){
          self.currentPage++;
          $scope.merge.start();
        }
      },
      isSetEnabled: () => {
        let self = $scope.page;
        return self.currentPage >= 1 && self.currentPage <= self.maxPage;
      },
      isFirstEnabled: () => {
        let self = $scope.page;
        return self.currentPage <= self.maxPage && self.currentPage > 1;
      },
      isLastEnabled: () => {
        let self = $scope.page;
        return self.currentPage >= 1 && self.currentPage < self.maxPage;
      },
      setPage: () => {
        if($scope.page.isSetEnabled()) {
          $scope.merge.start();
        }
      },
      init: (currentPage, maxPage, pageSize) => {
        let self = $scope.page;
        self.maxPage = maxPage;
        self.currentPage = currentPage + 1;
        self.pageSize = pageSize;
        self.isReady = true;
      }
    };

    let addColumn = TableTwo.addColumnProvider($scope.options, $scope.merge.state);

    $scope.columns = [
      addColumn("Created at", "created_at", function(value){return dateFormat(value.value, 'MM/dd/yyyy HH:mm:ss', "UTC");}),
      addColumn("Title", "title"),
      addColumn("Description", "description"),
      addColumn("Acknowlage", "accepntance_at"),
    ];

    $scope.markRead = (notification) => {
      UserService.markNotificationRead(notification.id).then((response) => {
        UserService.forceRefreshNotifications($scope.page).then(() => {
          $scope.merge.start();
        });
      });
    };

    $scope.merge.start();
  }
]);

